<template>
    <div>
      <a-card>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card title="社区版" :style="{ marginBottom: '16px' }">
              <p>rongbrain社区版可免费使用90%的功能.</p>
              <p>价格: 0￥/month</p>
              <!-- <a-button type="primary" @click="selectPlan('basic')">Select</a-button> -->
            </a-card>
          </a-col>
  
          <a-col :span="8">
            <a-card title="31天试用版" :style="{ marginBottom: '16px' }">
              <p>体验rongbrain全部功能</p>
              <p>价格: ￥30</p>
              <!-- <a-button type="primary" @click="selectPlan('standard')">Select</a-button> -->
            </a-card>
          </a-col>
  
          <a-col :span="8">
            <a-card title="366天尊享会员版本" :style="{ marginBottom: '16px' }">
              <p>全部rongbrain功能和及时的邮件和客户支持</p>
              <p>价格: ￥199</p>
              <!-- <a-button type="primary" @click="selectPlan('premium')">Select</a-button> -->
            </a-card>
          </a-col>
        </a-row>
        <div class="note">当前的会员均通过礼品码的形式获得，你可以通过我们的营销渠道索取。</div>
        <a-divider></a-divider>
  
        <a-table :columns="columns" :data-source="dataSource" :pagination="false"></a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        columns: [
          {
            title: '功能',
            dataIndex: 'feature',
            key: 'feature',
          },
          {
            title: '社区版本',
            dataIndex: 'freeVersion',
            key: 'freeVersion',
          },
          {
            title: '会员版本',
            dataIndex: 'premiumVersion',
            key: 'premiumVersion',
          },
        ],
        dataSource: [
          {
            key: '1',
            feature: '全部思维导图基础功能',
            freeVersion: '✓',
            premiumVersion: '✓',
          },
          {
            key: '2',
            feature: '插件上传字数>500字',
            freeVersion: 'x',
            premiumVersion: '✓',
          },
          {
            key: '3',
            feature: '软件内智能整合',
            freeVersion: 'x',
            premiumVersion: '✓',
          },
          {
            key: '3',
            feature: '智能整合算法',
            freeVersion: '1.0',
            premiumVersion: '2.0',
          },
          {
            key: '100',
            feature: '更多尊享服务',
            freeVersion: 'x',
            premiumVersion: '✓',
          },
          // Add more rows as needed
        ],
      };
    },
    methods: {
      selectPlan(plan) {
        alert(`Selected ${plan} plan`);
        // Add logic to handle plan selection, e.g., navigate to another page, etc.
      },
    },
  };
  </script>
  
  <style scoped>
.note{
    color:red;
    font-weight:bold;
}



  /* Add your custom styles here if needed */
  </style>
  