<template>
    <div class="container">
        <!-- <div id="back">
    <a href="/user">返回主页</a>
    </div> -->
    <div class="info">
        <h1 class="sci-fi-text">
    rongbrain 
  </h1>
  <a class="doctitle" href="/rbdocs">-查看使用手册-</a>

            <a class="donwloadpart" href="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/rongbrainlatest.rar"
                download="rongbrain安装包">-点击下载最新版rongbrain-</a>
       

</div>


<div class="usersystem">
    
</div>
<div class="product">
        <div class="desc">
            rongbrain 是一个基于云的知识图谱软件，可以帮你有效的组织你的信息，在这里你可以优雅的分享节点信息。和atomTask一起组织你的日常工作与学习。
            rognbrain可以和brainsearch插件一起工作,极致提高你的研究效率。rongbrain与atomTask一起构成了本公司用来培养人才的方案,知(rongbrain)行(atomTask)合一.
        </div>
        <div class="content">
            <div class="funcintro">
                <ul class="header">rongbrain提供这些功能</ul>
                <ul>思维导图基础功能</ul>
                <ul>在线分享思维导图</ul>
                <ul>生成思维导图图片</ul>
                <ul>右键粘贴分享思维导图</ul>
                <ul>丰富的笔记区功能</ul>
                <ul>多种链接方式</ul>
                <ul>浏览器插件支持</ul>
            </div>
            <div id="reference">
                <ul class="header">rongbrain参考的设计哲学</ul>
                <ul>gettting things done</ul>
                <ul>how to read a book</ul>
                <ul>毛泽东选集第一卷</ul>
                <ul>微习惯</ul>
                <ul>原则</ul>
                <ul>练习的心态</ul>
                <ul>a mind for number</ul>
                <ul>摩托车维修的艺术</ul>
                <ul>贫穷的本质</ul>
                <ul>务实思考的技术</ul>
                <ul>穷查理宝典</ul>
                <ul>项目管理的思考</ul>
                <ul>公司组织与运营</ul>
                <ul>dont make me think</ul>
                <ul>如何阅读一本书</ul>

            </div>


        </div>
        <div class="functionzone">
            <div id="funczone">
            <div>
             
                <ul class="header">思维导图基础功能🎻</ul>
                <ul>专注模式</ul>
                <ul>生成图片</ul>
                <ul>UI定制,定制专属模板</ul>
                <ul>多种节点连接方式</ul>
                <ul>更多的基础功能</ul>
            </div>


            <div>
               
                <ul class="header">多样的分享方式🎺</ul>
                <ul>生成图片到剪贴板</ul>
                <ul>文档分享模式/思维导图分享模式</ul>
                <ul>一键分享，可携带你喜欢的样式给用户</ul>
            </div>



            <div>
                
             
                <ul class="header">丰富的笔记区🎹</ul>
                <ul>支持图床图片</ul>
                <ul>支持文本，代码，latex，超链接</ul>

            </div>

            <div>
             
                <ul class="header">内置多种AI算法🎶</ul>
                <ul>AI智能整合</ul>
                <ul>各类客户端智能搜索(插件，app)</ul>
            </div>

            <div>
                <ul class="header">brainsearch丰富的插件功能💯</ul>
                <ul>支持最新版的chrome，edge浏览器</ul>             
                <ul>支持文本选中操作，上传到rongbrain</ul>
                <ul>支持在搜索引擎中检索自已的知识库(bing,google,baidu)</ul>
                <ul><a href="https://chromewebstore.google.com/detail/brainsearch/jolcjmhafjddkcekdbmpolcajccifpdp">点击下载brainseach chrome插件</a></ul>
                <ul><a href="https://microsoftedge.microsoft.com/addons/detail/brainsearch/ogakkiijibpnaponllaclpgglelijpgm">点击下载brainseach bing浏览器插件</a></ul>
           
           
            </div>



            <div>
                <ul class="header">即将上线😊</ul>
                           
                <ul>android 客户端</ul>
                <ul>更优秀的智能整合算法</ul> 
                <ul>支持大模型对话功能</ul>
                <ul>与atomTask更好的联动</ul>
            </div>
        </div>



            <div>定价</div>

            <Price/>




            <h3 class="font-bold text-center " data-aos="fade-up">
                rongbrain已经帮助用户组织
                <br>
                130,000+ 的知识节点
            </h3>


        </div>






        <button class="downloadbutton">
            <a href="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/rongbrainlatest.rar"
                download="rongbrain安装包">点击下载rongbrain</a>
        </button>
        <h1 class="sci-fi-text">
    rongbrain <span class="animated-text">Make Knowledge</span>
     P<img src="../assets/rocketpro.gif" alt="Rocket GIF" class="rocket-icon">
    wer
  </h1>


</div>
    </div>
</template>
    
<script>
import Price from  './priceforrongbrain.vue'
export default {
    name: 'reference',
    props: {
        msg: String
    },
    components:{
        Price,
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped >#back {
    /* position: absolute;; */
    left: 10%;
}
.sci-fi-text {
  font-family: 'scitext', sans-serif; 
  color: #00ff00; /* 设置绿色文本 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 添加文字阴影 */
  display: flex;
  align-items: center;
  word-spacing: 8px; 
}

.animated-text {
  display: inline-block;
  margin:5px;
  animation: glow 2s infinite alternate; /* 添加一个闪烁的动画效果 */
//   letter-spacing: 2px; 

}

@keyframes glow {
  from {
    color: #00ff00;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  to {
    color: #ff00ff; /* 设置另一种颜色 */
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.5); /* 设置另一种文字阴影 */
  }
}

.rocket-icon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    height: 100vh;
    margin: 0 10%; 


    .content {
        display: flex;
        flex-direction: row;
        .header {
  font-size: larger; /* 或者设置具体的大小值 */
  font-weight: bold;
}
        .funcintro {
            background: rgb(216, 16, 16);
            color: white;
            width: 50vw;
            text-align: left;
        }

        #reference {
            /* font-family: fangsong; */
            text-align: left;
            background: blue;
            color: white;
            width: 50vw;


        }
    }

    .desc {
    text-align: center;
    // margin: 20px;
    margin-bottom: 20px;
    font-family: "scitext";
    position: relative;
    padding: 20px; /* 添加内边距 */
    border: 1px solid #ccc; /* 添加边框 */
    border-radius: 8px; /* 添加边框圆角 */

    &::before {
        content: '®'; /* 商标 "r" 的Unicode字符或其他字符 */
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px; /* 根据需要调整字体大小 */
        color: #888; /* 商标颜色，可以根据需要调整 */
    }
}

    button {
        width: 200px;
        // left:48vw;
        // margin-left: auto;
    }

    .downloadbutton {
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
    }
}



.functionzone {

    display: flex;
    flex-direction: column;
    justify-content: center;



    #funczone {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

#funczone > div {
  padding: 20px;
  border-radius: 8px;
  text-align: left; /* 将文字左对齐 */
}

#funczone > div:nth-child(odd) {
  background-color: #f0f0f0; /* 设置奇数项的背景色 */
}

#funczone > div:nth-child(even) {
  background-color: #f5f5f5; /* 设置偶数项的背景色 */
}
.header {
  font-size: larger; /* 或者设置具体的大小值 */
  font-weight: bold;
}

    .font-bold {
        font-weight: 700;
        font-size: 40px;
    }
}

.info{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
}


.doctitle{
  font-size:medium;
  font-weight: bold;
  color:red;
  text-align: center;
//   background:red;
}



.donwloadpart{
  font-size:x-large;
  font-weight: bold;
  color:rgb(19, 7, 184);
  text-align: center;
//   background:red;
}
</style>
    